import React from "react"
import Layout from "../../components/h/layout"

const HalloweenDPage = () => (
  <Layout>
    <h2>A pencil to draw an eraser to rub.</h2>
    <h2>This is where you get all your stuff for art for kids hub.</h2>
  </Layout>
)
export default HalloweenDPage
